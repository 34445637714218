<template>
  <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">
    <div class="space-y-1 pb-8">
      <router-link
        v-for="item in navigation"
        :key="item.id"
        :to="item.href"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <component
          :is="item.icon"
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span class="truncate"> {{ item.name }} </span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import {
  BellIcon,
  UserCircleIcon,
  LockClosedIcon,
} from "@heroicons/vue/outline";

const navigation = [
  {
    id: 0,
    name: "Notifications",
    href: "/account/notifications",
    icon: BellIcon,
    current: false,
  },
  {
    id: 1,
    name: "Profile",
    href: "/account/profile",
    icon: UserCircleIcon,
    current: false,
  },
  {
    id: 1,
    name: "Security",
    href: "/account/security",
    icon: LockClosedIcon,
    current: false,
  },
];

export default {
  setup() {
    return {
      navigation,
    };
  },
};
</script>
